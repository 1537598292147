import { useEffect, useContext, useCallback } from "react";

import Header from "./Components/Headers";
import Products from "./Components/ProductTypes/Products";
import Items from "./Components/ProductTypes/Items";
import Context from "./Context";
import { v4 as uuid } from "uuid";
import styles from "./App.module.scss";

const apiUrl = `${process.env.REACT_APP_PLAID_API_URL}`.replace(/\/$/, '')

const App = () => {
  const { linkSuccess, isItemAccess, dispatch } = useContext(Context);

  const generateToken = useCallback(
    async () => {
      const unique_id = uuid();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const access_token = urlParams.get('access_token')
      const body = {
        client_user_id: unique_id,
        access_token: access_token,
      }
      const response = await fetch(`${apiUrl}/link/token/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      const data = await response.json();
      if (data) {
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      // Save the link_token to be used later in the Oauth flow.
      localStorage.setItem("link_token", data.link_token);
    },
    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      // do not generate a new token for OAuth redirect; instead
      // setLinkToken from localStorage
      if (window.location.href.includes("?oauth_state_id=")) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }
      generateToken();
    };
    init();
  }, [dispatch, generateToken]);

  return (
    <div className={styles.App}>
      <div className={styles.container}>
        <Header />
        {linkSuccess && (
          <>
            {isItemAccess && (
              <>
                <Products />
                <Items />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;
